

export const userInfo = {
    general: {
        display: "Vue d'ensemble",
        properties: {
            name: "Changer votre nom"
        }
    },
    presentation: {
        display: "Présentation",
        properties: {
            bio: "Ajouter / changer votre bio",
            interests: "Ajouter / changer des centres d'intérêts",
            skills: "Ajouter / changer vos compétences"
        }
    },
    socials: {
        display: "Réseaux sociaux",
        properties: {
            socials: "Ajouter un lien",
        }
    },
    contact: {
        display: "Coordonnées",
        properties: {
            address: "Ajouter / changer votre addresse",
            email: "Ajouter / changer votre email",
            phone: "Ajouter / changer votre numéro de téléphone",
        }
    }
}
