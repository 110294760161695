import React, { useContext, useState, useEffect } from "react";
import "./articles.css";
import Favo from "../../media/fav.jpeg";
import Favoli from "../../media/fav.jpeg";
import facebook from "../../media/facebook.svg";
import twitter from "../../media/twitter.svg";
import linkedin from "../../media/linkedin.svg";
import z from "../../media/z.png";
import { Context } from "../../Helper/Context";
import { fetchFavorites } from "../../HTTP/articles";
import { htmlDecode } from "../../Helper/utils"
const user = JSON.parse(localStorage.getItem('user'))


const ArticlesFav = () => {
  const [showPopup, setShowPopup] = useState(false);
  const {selection, setselection} = useContext(Context);
  const [favorites, setfavorites] = useState([]);
  const [error, setError] = useState("");

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleselected = () => {
    setselection(!selection);
  };

  useEffect(() => {
    fetchFavorites()
      .then((favs) => {
          setfavorites(favs)
      })
      .catch(() => setError("Une erreur est survenue, vous pouvez tenter de recharger la page."))
  }, [])


  return (
      <>
            { showPopup && <div className="blur-background"></div> }
            { showPopup && (
              <>

                <div className="popup">
              
                <div className="socialicons">
                  <img src={facebook}/>
                  <img src={linkedin}/>
                  <img src={twitter}/>
                  <img onClick={()=>{alert("partager  Article")}} className="zicon" src={z}/>
                </div>
                  <div style={{ display: "flex" }} className="headtitle">
                    <h1> Revue de Presse:</h1>
                    <span onClick={togglePopup} className="material-symbols-outlined">
                      close
                    </span>
                  </div>
                  <div>
                    <div className="Message">
                      <input
                        title="Write Message"
                        tabindex="i"
                        pattern="\d+"
                        placeholder="Théme ..."
                        className="MsgInput"
                        type="text"
                      />
                      <span className="l"></span>
                    </div>
                    <div className="Message">
                      <textarea
                        tabindex="i"
                        pattern="\d+"
                        placeholder="Description ..."
                        className="MsgInput"
                        rows={16}
                        style={{ height: "202px" }}
                      />
                      <span className="l"></span>
                    </div>


                    <div className="Message">
                        <img
                          className="favphoto"
                          src="https://www.ferra.ru/thumb/860x0/filters:quality(75):no_upscale()/imgs/2022/06/25/15/5467074/9b8ac64149ba0a542220a03b494c6ad99f9206aa.jpg"
                          alt="iphone 13 laying between plants on steel rail"
                        />
                    </div>


                  </div>
                </div>
              </>
            )}
                  

        <section className="articlesfav">
            { 
              (() => {
                if (error.length > 0){
                  return <h1>{error}</h1>
                }
              })
              ()
            } 

            <div className="titleDivfav" style={{ display: "flex", marginTop: 55, marginBottom: 35}}>
                <button onClick={togglePopup}> Créer une revue de presse</button>
            </div>

            {
              favorites.map((fav, i) => (
                    <div className="featuresfav" key={i * Math.random()* 1000} >
                        <div className="itemfav item1">
                          <div onClick={handleselected}>
                              <img
                                  className="item-photofav"
                                  src={ htmlDecode(fav.image || process.env.PUBLIC_URL + "/news_icon2.jpg") /**  IMAGE  */}
                                  alt="Une image d'article"
                                  onClick={() => window.open(htmlDecode(fav.link), "_blank")}
                                />
                          </div>
                          <div onClick={handleselected} className="arttfav">
                            <div className="item-headerfav">
                              <div style={{ display: "flex" }} className="imgdivfav">
                                <img src = { user.profile_picture || process.env.PUBLIC_URL+"/profile_pic_default_3.jpg" } />
                                <p>  { user.firstname + " " +  user.lastname }</p>
                              </div>
                              <ion-icon className="item-icon" name="bulb-outline"></ion-icon>
                            </div>
                            <a href={htmlDecode(fav.link)} target="_blank" style={{ textDecoration: "inherit", color: "inherit" }}>
                              <h3 className="item-titlefav">
                                  { htmlDecode(fav.title) }
                              </h3>
                              <p className="item-textfav">
                                  { fav.description }
                              </p>
                            </a>
                            {/* <a className="item-link" href="#">Click to see by yourself &rarr;</a> */}
                          </div>

                          { true ? 
                          (
                            <div className="actiondiv">
                              <input type="checkbox" />
                              <span onClick={() => alert("delete from favoris")} className="material-symbols-outlined" >
                                  delete
                              </span>
                            </div>
                          ) : 
                          (
                            <div className="favicon"><img src={Favo} /></div>
                          )
                          }
                        </div>
                    </div>
              ))
            }

        </section>
      </>
  );
};

export default ArticlesFav;
