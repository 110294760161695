import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import { Context } from "./Helper/Context";
import './App.css';
import GeneralContainerWrapper from "./global/GeneralContainer"
import Feed from "./Component/Feed/Feed"
import Articlesfav from "./Component/Home/articlesfav";
import Friends from "./Component/Friend/friend";
import Followed from "./Component/Suivis/suivis";
import FriendsMessages from "./Component/Listmsg/Listmsg";
import Profile from "./Component/Profile/Profile";
import GoogleCSE from "./Component/Home/GoogleCSE"
import Articles from "./Component/Home/articles"
import Login from "./Component/Login/Login"
import Subscription from "./Component/Subscription/Subscription";
import Conditions from "./Component/CONDITIONS/Conditions";
import Legales from "./Component/CONDITIONS/Legales";
import POLITIQUE from "./Component/CONDITIONS/POLITIQUE";
import { countries } from './global/countriesAndSources'
import Conversation from './Component/Conversation/Conversation'
import Audiocall from './Component/call/audiocall'
import VideoCall from './Component/call/videocall'

let W_ARTICLES = GeneralContainerWrapper(Articles)
let W_ARTICLES_FAV = GeneralContainerWrapper(Articlesfav)
let W_GOOGLE_CSE = GeneralContainerWrapper(GoogleCSE)
let W_FEED = GeneralContainerWrapper(Feed)
let W_FRIENDS = GeneralContainerWrapper(Friends)
let W_FRIENDS_MESSAGES = GeneralContainerWrapper(FriendsMessages)
let W_PROFILE = GeneralContainerWrapper(Profile)
let W_fOLLOWED = GeneralContainerWrapper(Followed)
let W_AUDIOCALL = GeneralContainerWrapper(Audiocall)
let W_VIDEOCALL = GeneralContainerWrapper(VideoCall)
let W_CONVERSATION = GeneralContainerWrapper(Conversation)


function App() {
  const [Fil, SetFil] = useState("fil");
  const [selection, setselection] = useState(false);
  const [publicationID, setPublicationID] = useState(false);
  const [friendAction, setFriendAction] = useState(false)

  useEffect(() => {
      document.title = "Zemus - Locate the news"
  }, [])


  // Redirection before connection
  if (window.location.href.split("/")[3] !== "login" && (!localStorage.getItem("token") || !localStorage.getItem("token_age")) ) {
      window.location.href = "/login"
  }
  // Si le token est trop vieux, on enlève tout du local storage, donc au prochain passage dans le routeur : redirection
  if (localStorage.getItem("token_age") && new Date().getTime() - parseInt(localStorage.getItem("token_age")) > 28800000) { // Différence de 8 heures en millisecondes
    localStorage.removeItem("token")
    localStorage.removeItem("token_age")
    localStorage.removeItem("user")
  }
  return (
      <Context.Provider
        value={{
          Fil,
          SetFil,
          selection,
          setselection,
          publicationID,
          setPublicationID,
          friendAction,
          setFriendAction
        }}
      >

          <div className="App">
          <BrowserRouter>
              <Routes>
                <Route path="/" element={<W_GOOGLE_CSE />}></Route>
                <Route path="/login" element={<Login />}></Route>

                <Route path="/search" element={ <W_GOOGLE_CSE/> }> </Route>
                <Route path="/articles" element={ <W_ARTICLES /> }> </Route>
                <Route path="/feed" element={ <W_FEED/> }> </Route>
                <Route path="/favourites" element={ <W_ARTICLES_FAV/> }> </Route> 
                <Route path="/friends" element={ <W_FRIENDS/> }> </Route>
                <Route path="/followed" element={ <W_fOLLOWED/> }> </Route>
                <Route path="/friends-msg" element={ <W_FRIENDS_MESSAGES/> }> </Route>
                <Route path="/profile" element={ <W_PROFILE/> }> </Route>
                <Route path="/conversation" element={ <W_CONVERSATION/> }> </Route>
                <Route path="/audiocall" element={ <W_AUDIOCALL/> }> </Route>
                <Route path="/videocall" element={ <W_VIDEOCALL/> }> </Route>

                <Route path="/Subscription" element={<Subscription />}></Route>
                <Route path="/Conditions" element={<Conditions />}></Route>
                <Route path="/Legales" element={<Legales />}></Route>
                <Route path="/POLITIQUE" element={<POLITIQUE />}></Route>
              </Routes>
            </BrowserRouter>
          </div>

    </Context.Provider>
  );
}

export default App;


