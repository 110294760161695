

/**
 * @arg {string} text
 * Decodes the urls which have characters as HTML entities.
 * @returns {string}
**/
export function htmlDecode (text) {
    var textArea = document.createElement('textarea')
    textArea.innerHTML = text
    return textArea.value
}
