
const API_URL = "http://zemus.info:8080"
const API_SCHEME = "http"
const API_HOST_NAME = "zemus.info"
const API_PORT =  "8080"

export default {
    API_URL,
    API_SCHEME,
    API_HOST_NAME,
    API_PORT
}



