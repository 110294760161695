import authHeader from "./auth.header"
import constants from './constants.js'

const HOST_URL = constants.API_SCHEME + "://" + constants.API_HOST_NAME + ":" + constants.API_PORT;


/**
 * @param {{title, link, image?, country, publication_date, description}} favorite 
 * Every property in favorite is a string
 * @returns boolean
**/
export async function addFavorite(favorite) {
    const userId = JSON.parse(localStorage.getItem('user')).id
    if (!userId) return

    const response = await fetch(`${HOST_URL}/users/${userId}/favorites`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ... authHeader()
        },
        body: JSON.stringify(favorite)
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return false
    }

    return true
}


/**
  * Returns a list of favorites.
  * @returns {[
  * favorite: {
    * title: string, 
    * link: string, 
    * image: string, 
    * country: string 
    * publication_date: string, 
    * description: string 
    * }
  * ]}
*/
export async function fetchFavorites() {
    const userId = JSON.parse(localStorage.getItem('user')).id
    if (!userId) return
    const response = await fetch(`${HOST_URL}/users/${userId}/favorites`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            ... authHeader()
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    const jsonResponse = await response.json()
    const favorites = jsonResponse.favorites
    return favorites
}