
export const countries = [
    {"name": "Afghanistan", "code": "af", cse: "018091190003298728883:v1cpngmakjm"},
    {"name": "Albania", "code": "al", cse: "018091190003298728883:1rilpwdfzj8"},
    {"name": "Algeria", "code": "dz", cse: "018091190003298728883:ww7ppunocsm"},
    {"name": "Andorra", "code": "ad", cse: "018091190003298728883:dkicqn8kgyq"},
    {"name": "Angola", "code": "ao", cse: "018091190003298728883:9ihhyhyv7ck"},
    {"name": "Antigua & Barbuda", "code": "ag", cse: "018091190003298728883:asq6urugsug"},
    {"name": "Argentina", "code": "ar", cse: "018091190003298728883:_h6lhibqux0"},
    {"name": "Armenia", "code": "am", cse: "018091190003298728883:l0ep7e96qa8"},
    {"name": "Australia", "code": "au", cse: "004899679196972058950:vcvmuqp3x2k"},
    {"name": "Austria", "code": "at", cse: "018091190003298728883:ntnu5_8xxrc"},
    {"name": "Azerbaijan", "code": "az", cse: "018091190003298728883:yce8u3rqsly"},
    {"name": "Bahamas", "code": "bs", cse: "018091190003298728883:a2czzvktoqy"},
    {"name": "Bahrain", "code": "bh", cse: "018091190003298728883:3dxnoyip0b8"},
    {"name": "Bangladesh", "code": "bd", cse: "018091190003298728883:hjvojdxsz0c"},
    {"name": "Barbados", "code": "bb", cse: "018091190003298728883:lyz2wltp57c"},
    {"name": "Belarus", "code": "by", cse: "017907537962060352927%3Aktliaoxmows"},
    {"name": "Belgium", "code": "be", cse: "018091190003298728883:7wix4cvn8ey"},
    {"name": "Belize", "code": "bz", cse: "018091190003298728883:iqcass3nygc"},
    {"name": "Benin", "code": "bj", cse: "018091190003298728883:-d2o_jio5dm"},
    {"name": "Bhutan", "code": "bt", cse: "018091190003298728883:by_amx9kom8"},
    {"name": "Bolivia", "code": "bo", cse: "018091190003298728883:6w1ew9mqlya"},
    {"name": "Bosnia & Herzegovina", "code": "ba", cse: "017907537962060352927%3Awdzmj7005_e"},
    {"name": "Botswana", "code": "bw", cse: "018091190003298728883:orjm-a8ck-u"},
    {"name": "Brazil", "code": "br", cse: "004899679196972058950:exmb_qdc6tw"},
    {"name": "Brunei", "code": "bn", cse: "018091190003298728883:djsfapfipho"},
    {"name": "Bulgaria", "code": "bg", cse: "018091190003298728883:97qdbezsh2w"},
    {"name": "Burkina Faso", "code": "bf", cse: "018091190003298728883:bk4jhwznutm"},
    {"name": "Burundi", "code": "bi", cse: "018091190003298728883:4lnkexsjbzq"},
    {"name": "Cambodia", "code": "kh", cse: "018091190003298728883:nqbdp7uoa4i"},
    {"name": "Cameroon", "code": "cm", cse: "018091190003298728883:zmc6j4sztng"},
    {"name": "Canada", "code": "ca", cse: "018091190003298728883:mo81hr8loza"},
    {"name": "Cape Verde", "code": "cv", cse: "018091190003298728883:rrtme-omq5a"},
    {"name": "Central African Republic", "code": "cf", cse: "005897242965503679309:eccjptfb3vo"},
    {"name": "Chad", "code": "td", cse: "013581124321713960683:bltrtkbndy4"},
    {"name": "Chile", "code": "cl", cse: "004899679196972058950:5h6rq3vugom"},
    {"name": "China", "code": "cn", cse: "018091190003298728883:gyaxcjs9fiw"},
    {"name": "Colombia", "code": "co", cse: "011721385535203573713:qxam5emfhba"},
    {"name": "Comoros", "code": "km", cse: "011721385535203573713:bt84byokbne"},
    {"name": "Congo - Brazzaville", "code": "cg", cse: "011721385535203573713:zfy7kgohoha"},
    {"name": "Congo - Kinshasa", "code": "cd", cse: "011721385535203573713:zfy7kgohoha"},
    {"name": "Cook Islands", "code": "ck", cse: "003393126044876575554:bjbliszn2ym"},
    {"name": "Costa Rica", "code": "cr", cse: "011721385535203573713:twstqbmnxve"},
    {"name": "Côte d’Ivoire", "code": "ci", cse: "011721385535203573713:ickrhctedmu"},
    {"name": "Croatia", "code": "hr", cse: "011721385535203573713:ls3onyjx3wy"},
    {"name": "Cuba", "code": "cu", cse: "011721385535203573713:pmjoqrjehnk"},
    {"name": "Cyprus", "code": "cy", cse: "018091190003298728883:gcnwgtppitu"},
    {"name": "Czechia", "code": "cz", cse: "005897242965503679309:dkvw0lwq1gq"},
    {"name": "Denmark", "code": "dk", cse: "011721385535203573713:3i9anhuanzw"},
    {"name": "Djibouti", "code": "dj", cse: "011721385535203573713:xsk4xbsgvns"},
    {"name": "Dominica", "code": "dm", cse: "005897242965503679309:_ykks3dfjeq"},
    {"name": "Dominican Republic", "code": "do", cse: "005897242965503679309:_ykks3dfjeq"},
    {"name": "Ecuador", "code": "ec", cse: "011721385535203573713:t_ub2er4few"},
    {"name": "Egypt", "code": "eg", cse: "011721385535203573713:u7nzy7ff3qo"},
    {"name": "El Salvador", "code": "sv", cse: "011721385535203573713:5zbumni2364"},
    {"name": "Equatorial Guinea", "code": "gq", cse: "011721385535203573713:2ejhdjpdhuy"},
    {"name": "Eritrea", "code": "er", cse: "011721385535203573713:svbcqvhh8ge"},
    {"name": "Estonia", "code": "ee", cse: "011721385535203573713:be69cyaqvk4"},
    {"name": "Eswatini", "code": "sz", cse: "013581124321713960683:vihapujwjiw"},
    {"name": "Ethiopia", "code": "et", cse: "011721385535203573713:78w1fykyw0e"},
    {"name": "European Union", "code": "ue", cse: "018091190003298728883:4f0plk-khh0"},
    {"name": "Fiji", "code": "fj", cse: "011721385535203573713:c2fri5bqzla"},
    {"name": "Finland", "code": "fi", cse: "011721385535203573713:xob6c7lcjca"},
    {"name": "France", "code": "fr", cse: "004899679196972058950:gvpguoasgrm "}, /* journaux: 84d8bb2aa39a54b37 */
    {"name": "Gabon", "code": "ga", cse: "011721385535203573713:z7bqzjhitqq"},
    {"name": "Gambia", "code": "gm", cse: "011721385535203573713:chgrd26rb48"},
    {"name": "Georgia", "code": "ge", cse: "011721385535203573713:j2fwzpytybk"},
    {"name": "Germany", "code": "de", cse: "004899679196972058950:-pvhdtnf0og"},
    {"name": "Ghana", "code": "gh", cse: "011721385535203573713:lbg6zzkfvro"},
    {"name": "Greece", "code": "gr", cse: "011721385535203573713:kwyibaxzov4"},
    {"name": "Grenada", "code": "gd", cse: "011721385535203573713:zxzoouczkv0"},
    {"name": "Guatemala", "code": "gt", cse: "011721385535203573713:hd9p85fll8a"},
    {"name": "Guinea", "code": "gn", cse: "011721385535203573713:zjn8mmtqqu8"},
    {"name": "Guinea-Bissau", "code": "gw", cse: "011721385535203573713:wa34-fri3-g"},
    {"name": "Guyana", "code": "gy", cse: "011721385535203573713:lz_1b2j86li"},
    {"name": "Haiti", "code": "ht", cse: "011721385535203573713:h9s0oret3s0"},
    {"name": "Honduras", "code": "hn", cse: "011721385535203573713:gaoa1y8xii4"},
    {"name": "Hungary", "code": "hu", cse: "011721385535203573713:e2kgpuibibo"},
    {"name": "Iceland", "code": "is", cse: "003393126044876575554:sl8jouqd_y0"},
    {"name": "India", "code": "in", cse: "003393126044876575554:yf5eis4jsmk"},
    {"name": "Indonesia", "code": "id", cse: "003393126044876575554:cwxw8fkhcdg"},
    {"name": "Iran", "code": "ir", cse: "003393126044876575554:vron4f2xjmk"},
    {"name": "Iraq", "code": "iq", cse: "003393126044876575554:vtdddhs2yrw"},
    {"name": "Ireland", "code": "ie", cse: "003393126044876575554:am0vthycdfc"},
    {"name": "Israel", "code": "il", cse: "003393126044876575554:adpv4eamiew"},
    {"name": "Italy", "code": "it", cse: "003393126044876575554:smyjgicmgy8"},
    {"name": "Jamaica", "code": "jm", cse: "003393126044876575554:s53w1pi7vpc"},
    {"name": "Japan", "code": "jp", cse: "003393126044876575554:yfcykx3nt60"},
    {"name": "Jordan", "code": "jo", cse: "003393126044876575554:p6gx2matabe"},
    {"name": "Kazakhstan", "code": "kz", cse: "003393126044876575554:kjbxj0p2fbo"},
    {"name": "Kenya", "code": "ke", cse: "003393126044876575554:8iqa72qhb1y"},
    {"name": "Kiribati", "code": "ki", cse: "003393126044876575554:ahh7tsnx-0y"},
    {"name": "Kosovo", "code": "xk", cse: "018091190003298728883:yvqqqyu08iz"},
    {"name": "Kuwait", "code": "kw", cse: "003393126044876575554:sywi6c4h3di"},
    {"name": "Kyrgyzstan", "code": "kg", cse: "003393126044876575554:8fr6vlevptc"},
    {"name": "Kyrgyzstan", "code": "kg", cse: "c6311254dcc95fb31"},
    {"name": "Laos", "code": "la", cse: "003393126044876575554:_lioxqivaeo"},
    {"name": "Latvia", "code": "lv", cse: "003393126044876575554:vawimvzi168"},
    {"name": "Lebanon", "code": "lb", cse: "003393126044876575554:5exylvyiybg"},
    {"name": "Lesotho", "code": "ls", cse: "003393126044876575554:nwuk9okqei8"},
    {"name": "Liberia", "code": "lr", cse: "016168714383393267599:xedyseq2mry"},
    {"name": "Libya", "code": "ly", cse: "016168714383393267599:hhxlfzvbw9m"},
    {"name": "Liechtenstein", "code": "li", cse: "016168714383393267599:byobmtqaacq"},
    {"name": "Lithuania", "code": "lt", cse: "016168714383393267599:kdcpitmqqho"},
    {"name": "Luxembourg", "code": "lu", cse: "016168714383393267599:uytgzlwlv2e"},
    {"name": "Madagascar", "code": "mg", cse: "016168714383393267599:kerq0clw9wq"},
    {"name": "Malawi", "code": "mw", cse: "005897242965503679309:v6ak4trrld4"},
    {"name": "Malaysia", "code": "my", cse: "005897242965503679309:0endly-ozeu"},
    {"name": "Maldives", "code": "mv", cse: "005897242965503679309:qsjxdwmzqfg"},
    {"name": "Mali", "code": "ml", cse: "005897242965503679309:kol689qztge"},
    {"name": "Malta", "code": "mt", cse: "005897242965503679309:dwc_aancpkc"},
    {"name": "Marshall Islands", "code": "mh", cse: "003393126044876575554:sbkmgwkpe60"},
    {"name": "Mauritania", "code": "mr", cse: "017907537962060352927%3Aljb3za8tzcu"},
    {"name": "Mauritius", "code": "mu", cse: "003393126044876575554:4h1v7mmedri"},
    {"name": "Mexico", "code": "mx", cse: "005897242965503679309:5cpfca4ymri"},
    {"name": "Micronesia", "code": "fm", cse: "005897242965503679309:p4wvpgfcxba"},
    {"name": "Moldova", "code": "md", cse: "005897242965503679309:cxukr3k7j90"},
    {"name": "Monaco", "code": "mc", cse: "005897242965503679309:jbl_ps4ne3m"},
    {"name": "Mongolia", "code": "mn", cse: "005897242965503679309:_do-htmigcq"},
    {"name": "Montenegro", "code": "me", cse: "005897242965503679309:csri_1gl7qw"},
    {"name": "Morocco", "code": "ma", cse: "005897242965503679309:ccj366jtaew"},
    {"name": "Mozambique", "code": "mz", cse: "005897242965503679309%3Anwqqesxuznc"},
    {"name": "Myanmar (Burma)", "code": "mm", cse: "018091190003298728883:yhedvrjiavq"},
    {"name": "Namibia", "code": "na", cse: "005897242965503679309:xh4x2eyktau"},
    {"name": "Nauru", "code": "nr", cse: "005897242965503679309:ak7vw7jm2ke"},
    {"name": "Nepal", "code": "np", cse: "005897242965503679309:b9t6gehyude"},
    {"name": "Netherlands", "code": "nl", cse: "005897242965503679309:j6hgy7nfw0i"},
    {"name": "New Zealand", "code": "nz", cse: "005897242965503679309:s6mud4eyytw"},
    {"name": "Nicaragua", "code": "ni", cse: "005897242965503679309:sk_t0nva_44"},
    {"name": "Niger", "code": "ne", cse: "005897242965503679309:ed1dq2gpmuc"},
    {"name": "Nigeria", "code": "ng", cse: "005897242965503679309:a4ju7v1dcbm"},
    {"name": "Niue", "code": "nu", cse: "017907537962060352927:pi-i0t63bzu"},
    {"name": "North Korea", "code": "kp", cse: "011721385535203573713:6r2svpn9k0c"},
    {"name": "North Macedonia", "code": "mk", cse: "016168714383393267599:63sr7mwwnmq"},
    {"name": "Norway", "code": "no", cse: "005897242965503679309:lyldticgcgm"},
    {"name": "Oman", "code": "om", cse: "005897242965503679309:bzeb7kcdmho"},
    {"name": "Pakistan", "code": "pk", cse: "005897242965503679309:rnh2govxwgy"},
    {"name": "Palau", "code": "pw", cse: "005897242965503679309:fav4t_4qdhk"},
    {"name": "Palestinian Territories", "code": "ps", cse: "005897242965503679309:k8bx6f_sfhu"},
    {"name": "Panama", "code": "pa", cse: "005897242965503679309:xs5mv0i4dnu"},
    {"name": "Papua New Guinea", "code": "pg", cse: "005897242965503679309:hxuq2dgrkz4"},
    {"name": "Paraguay", "code": "py", cse: "005897242965503679309:6zms2ogtsjq"},
    {"name": "Peru", "code": "pe", cse: "005897242965503679309:ausdf-utvge"},
    {"name": "Philippines", "code": "ph", cse: "005897242965503679309:8paro9li-bu"},
    {"name": "Poland", "code": "pl", cse: "005897242965503679309:majkgdmmola"},
    {"name": "Portugal", "code": "pt", cse: "005897242965503679309%3Ah21knfri9ky"},
    {"name": "Qatar", "code": "qa", cse: "005897242965503679309:ifvxbjg81-k"},
    {"name": "Romania", "code": "ro", cse: "005897242965503679309:yugee8v8n0w"},
    {"name": "Russia", "code": "ru", cse: "005897242965503679309:i6yyyanxc54"},
    {"name": "Rwanda", "code": "rw", cse: "013581124321713960683:z9ipb1peabg"},
    {"name": "Samoa", "code": "ws", cse: "017907537962060352927%3Aatujw_ktdtu"},
    {"name": "San Marino", "code": "sm", cse: "013581124321713960683:j98yujmlhzk"},
    {"name": "São Tomé & Príncipe", "code": "st", cse: "013581124321713960683:cvzjaoboflu"},
    {"name": "Saudi Arabia", "code": "sa", cse: "018091190003298728883:usfbvcyarr4"},
    {"name": "Sécurités nationales", "code": "kg", cse: "003393126044876575554:8fr6vlevptc"},
    {"name": "Sécurités nationales", "code": "kg", cse: "c6311254dcc95fb31"},
    {"name": "Senegal", "code": "sn", cse: "013581124321713960683:1v5xr6re7r0"},
    {"name": "Serbia", "code": "rs", cse: "013581124321713960683:gjf3gkchnh0"},
    {"name": "Seychelles", "code": "sc", cse: "013581124321713960683:xudx67hozt8"},
    {"name": "Sierra Leone", "code": "sl", cse: "013581124321713960683:ls3g4xr3q8y"},
    {"name": "Singapore", "code": "sg", cse: "013581124321713960683:nfmwxixqhgk"},
    {"name": "Slovakia", "code": "sk", cse: "013581124321713960683:hnez0atyrde"},
    {"name": "Slovenia", "code": "si", cse: "013581124321713960683:sye2fdcggbe"},
    {"name": "Solomon Islands", "code": "sb", cse: "003393126044876575554:u6efj7tyvmw"},
    {"name": "Somalia", "code": "so", cse: "013581124321713960683:afim9hzowwc"},
    {"name": "South Africa", "code": "za", cse: "018091190003298728883:b41hlozr-hg"},
    {"name": "South Korea", "code": "kr", cse: "011721385535203573713:o0y1ms1dtb8"},
    {"name": "South Sudan", "code": "ss", cse: "013581124321713960683:rszw96saamq"},
    {"name": "Spain", "code": "es", cse: "011721385535203573713:blcjji8uhbu"},
    {"name": "Sri Lanka", "code": "lk", cse: "013581124321713960683:f2zvr-tlncu"},
    {"name": "St. Kitts & Nevis", "code": "kn", cse: "013581124321713960683:zbbutv2jhva"},
    {"name": "St. Lucia", "code": "lc", cse: "013581124321713960683:rshhytzsmiw"},
    {"name": "St. Vincent & Grenadines", "code": "vc", cse: "018091190003298728883:_pzodtq6bpo"},
    {"name": "Sudan", "code": "sd", cse: "013581124321713960683:ac2rgl6g0b4"},
    {"name": "Suriname", "code": "sr", cse: "013581124321713960683:bcr70a8rrvs"},
    {"name": "Sweden", "code": "se", cse: "013581124321713960683:3ua8thbhlwy"},
    {"name": "Switzerland", "code": "ch", cse: "013581124321713960683:emlwm4eibs8"},
    {"name": "Syria", "code": "sy", cse: "013581124321713960683:y4sxk-tia1s"},
    {"name": "Taiwan", "code": "tw", cse: "013581124321713960683:xij-2q8akei"},
    {"name": "Tajikistan", "code": "tj", cse: "013581124321713960683:fok8u0i4_40"},
    {"name": "Tanzania", "code": "tz", cse: "013581124321713960683:diirynd1pdy"},
    {"name": "Thailand", "code": "th", cse: "013581124321713960683:twrytb4x0x8"},
    {"name": "Think tank", "code": "bm", cse: "14608f74ed7cb2443"},
    {"name": "Timor-Leste", "code": "tl", cse: "017907537962060352927:bt576hfpsag"},
    {"name": "Togo", "code": "tg", cse: "013581124321713960683:ahqs2-vf9mk"},
    {"name": "Tonga", "code": "to", cse: "017907537962060352927:m9azw_x5zr0"},
    {"name": "Trinidad & Tobago", "code": "tt", cse: "013581124321713960683:za5k4kpb110"},
    {"name": "Tunisia", "code": "tn", cse: "013581124321713960683:1mqjuiwqpks"},
    {"name": "Turkey", "code": "tr", cse: "013581124321713960683:dh6jkcytji4"},
    {"name": "Turkmenistan", "code": "tm", cse: "013581124321713960683:ywwlemluwq4"},
    {"name": "Tuvalu", "code": "tv", cse: "013581124321713960683:ezlk1fvjjr0"},
    {"name": "Uganda", "code": "ug", cse: "005897242965503679309:hgzrdhigfas"},
    {"name": "Ukraine", "code": "ua", cse: "013581124321713960683:dn-rem_pyl4"},
    {"name": "United Arab Emirates", "code": "ae", cse: "011721385535203573713:ps-a49ir7qu"},
    {"name": "United Kingdom", "code": "gb", cse: "005897242965503679309:lyciqtpz07q"},
    {"name": "United States", "code": "us", cse: "81ea303838d5c463a"},
    {"name": "Université", "code": "ms", cse: "3632327f3c8225728"},
    {"name": "Uruguay", "code": "uy", cse: "013581124321713960683:nn3tblf-ohe"},
    {"name": "Uzbekistan", "code": "uz", cse: "005897242965503679309:njtv-zfft8a"},
    {"name": "Vanuatu", "code": "vu", cse: "013581124321713960683:w4e2z1n_d5k"},
    {"name": "Vatican City", "code": "va", cse: "013581124321713960683:qgx2jqeha40"},
    {"name": "Venezuela", "code": "ve", cse: "013581124321713960683:o0qwslzjjhg"},
    {"name": "Vietnam", "code": "vn", cse: "013581124321713960683:welfoblgo0k"},
    {"name": "Yemen", "code": "ye", cse: "013581124321713960683:ihi2hxbubl8"},
    {"name": "Zambia", "code": "zm", cse: "013581124321713960683:r35a6vkfeo4"},
    {"name": "Zimbabwe", "code": "zw", cse: "013581124321713960683:a0_cjumclhg"}
]

export const sourceTypes = [
    {
        name: "Toutes les sources",
        code_name: "all"
    },
    {
        name: "journaux",
        code_name: "journals"
    },
    {
        name: "universités",
        code_name: "universities"
    },
    {
        name: "ambassades",
        code_name: "ambassies"
    },
    {
        name: "partis politiques",
        code_name: "political_parties"
    },
    {
        name: "ministères",
        code_name: "ministers"
    },
]

export const listMiddle = [
    "De quoi parle-t-on ?",
    "De quels pays parle-t-on ?",
    "Quels pays en parlent ?"
]
