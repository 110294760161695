import authHeader from "./auth.header"
import constants from './constants.js'

const HOST_URL = constants.API_SCHEME + "://" + constants.API_HOST_NAME + ":" + constants.API_PORT;


async function getAllFriends(){
    const userId = JSON.parse(localStorage.getItem('user')).id
    if (!userId) return

    const response = await fetch(`${HOST_URL}/users/${userId}/friends`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            ... authHeader()
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    return await response.json()
}

async function getFriendRequests(){
    try {
        const userId = JSON.parse(localStorage.getItem('user')).id
        if (!userId) return
    
        const response = await fetch(`${HOST_URL}/users/${userId}/friends/requests`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                ... authHeader()
            },
        })
    
        if (!response.ok) {
            console.log("request failed\n. response code, ", response.code)
            console.log("response payload, ", await response.json())
            return []
        }
    
        return (await response.json()).friend_requests || []
    }
    catch (err) {
        console.log("err at getFriendRequests", err)
        return []
    }
}

async function acceptFriendship(friendId){
    try {
        const userId = JSON.parse(localStorage.getItem('user')).id
        if (!userId) return
    
        const response = await fetch(`${HOST_URL}/users/${userId}/friends/${friendId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ... authHeader()
            },
        })
    
        if (!response.ok) {
            console.log("request failed\n. response code, ", response.code)
            console.log("response payload, ", await response.json())
            return false
        }
        
        return true
    }
    catch (err) {
        console.log("err at acceptFriendship", err)
    }
}

async function removeFriend(friendId) {
    try {
        const userId = JSON.parse(localStorage.getItem('user')).id
        if (!userId) return
    
        const response = await fetch(`${HOST_URL}/users/${userId}/friends/${friendId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ... authHeader()
            },
        })
    
        if (!response.ok) {
            console.log("request failed\n. response code, ", response.code)
            console.log("response payload, ", await response.json())
            return false
        }
        
        console.log(await response.json())
        return true
    }
    catch (err) {
        console.log("err at removeFriend", err)
    }
}

export default {
    getAllFriends,
    getFriendRequests,
    acceptFriendship,
    removeFriend,
}