import React, { useState, useEffect, useContext } from "react";
import "./friend.css";
import amis from "../../media/amis.png";
import cancel from "../../media/cancel.svg";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { Context } from "../../Helper/Context";
import userApi from "../../HTTP/user"

const Friend = () => {

  const [searchTerm, setSearchTerm] = useState("");
  const [friends, setFriends] = useState([]);
  const { friendAction, setFriendAction } = useContext(Context);

  useEffect(() => {
      userApi.getAllFriends().then((f) => setFriends(f))
  }, [friendAction])

  const rejectFriend = (friendId) => {
    userApi.removeFriend(friendId)
    setFriendAction(!friendAction)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    alert(searchTerm)
  }

  return (
    <div style={{ display: "flex" }}>
      <div className="friendcontainer">
        <div className="titlefriend">
          <img src={amis} />
          <h1> Tous les amis </h1>
        </div>
        <div className="allFriends">
          {
            friends.map((f, index) => 
              <div className="friend" key={index}>
                <img src={f.profile_picture || process.env.PUBLIC_URL + "/profile_pic_default_2.svg" || "https://i.imgur.com/A7lNstm.jpg"} />
                <div className="infodivfriend">
                  <p> {f.firstname} {f.lastname} </p>
                  <span className="infofriendspan"> {f.email} </span>
                </div>
                <img id="imgCancel" onClick={() => { rejectFriend(f.id) }} src={cancel} />
              </div>
            )
          }
        </div>
      </div>

      <div style={{ marginLeft: "20px" }}>

        <div className="serach">
          <Paper
            component="form"
            onSubmit={handleSubmit}
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "386px",
              marginTop: 0,
              borderRadius: "67px",
              border: "1px solid #008eb5"
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Recherche amis ..."
              inputProps={{ "aria-label": "Rechercher" }}
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
            />
            <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>


        <h2 className="h2seg">Suggestions:</h2>
        <div className="allFriendsseg">
          <div className="friendseg">
            <img src="https://i.imgur.com/A7lNstm.jpg" />
            <div>
              <p> Salah Houbni </p>
              <span className="infoseg">@salah_polib</span>


            </div>
            <button>Suivre</button>
          </div>
          <div className="friendseg">
            <img src="https://i.imgur.com/A7lNstm.jpg" />
            <div>
              <p> Salah Houbni </p>
              <span className="infoseg">@salah_polib</span>


            </div>
            <button>Suivre</button>
          </div>
          <div className="friendseg">
            <img src="https://i.imgur.com/A7lNstm.jpg" />
            <div>
              <p> Salah Houbni </p>
              <span className="infoseg">@salah_polib</span>


            </div>
            <button>Suivre</button>
          </div>
          <div className="friendseg">
            <img src="https://i.imgur.com/A7lNstm.jpg" />
            <div>
              <p> Salah Houbni </p>
              <span className="infoseg">@salah_polib</span>


            </div>
            <button>Suivre</button>
          </div>
          <div className="friendseg">
            <img src="https://i.imgur.com/A7lNstm.jpg" />
            <div>
              <p> Salah Houbni </p>
              <span className="infoseg">@salah_polib</span>


            </div>
            <button>Suivre</button>
          </div>
          <div className="friendseg">
            <img src="https://i.imgur.com/A7lNstm.jpg" />
            <div>
              <p> Salah Houbni </p>
              <span className="infoseg">@salah_polib</span>


            </div>
            <button>Suivre</button>
          </div>


          <div className="friendseg">
            <img src="https://i.imgur.com/A7lNstm.jpg" />
            <div>
              <p> Salah Houbni </p>
              <span className="infoseg">@salah_polib</span>


            </div>
            <button>Suivre</button>
          </div>


          <div className="friendseg">
            <img src="https://i.imgur.com/A7lNstm.jpg" />
            <div>
              <p> Salah Houbni </p>
              <span className="infoseg">@salah_polib</span>


            </div>
            <button>Suivre</button>
          </div>




        </div>

      </div>


    </div>
  );
};

export default Friend;
