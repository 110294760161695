import React, { useContext, useState, useEffect} from "react";
import Stack from "@mui/material/Stack";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Context } from "../../Helper/Context";
import etoille from "../../media/star.png"
import "./Home.css";
import "./GoogleCSE.css"
import { countries, sourceTypes, listMiddle } from "../../global/countriesAndSources"

const user = JSON.parse(localStorage.getItem("user"))

const StyledMenu = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "right",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "right",
		}}
		{...props}
	/>
	))(({ theme }) => ({
	"& .MuiPaper-root": {
		borderRadius: 8,
		marginTop: theme.spacing(1),
		minWidth: 210,
		color:
			theme.palette.mode === "light"
				? "rgb(55, 65, 81)"
				: theme.palette.grey[300],
		boxShadow:
			"rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
		"& .MuiMenu-list": {
			padding: "4px 0",
		},
		"& .MuiMenuItem-root": {
			"& .MuiSvgIcon-root": {
				fontSize: 25,
				color: theme.palette.text.secondary,
				// marginRight: theme.spacing(1.5),
			},
			"&:active": {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity
				),
			},
		},
	},
}));

const StyledMenu1 = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "right",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "right",
		}}
		{...props}
	/>
	))(({ theme }) => ({
	"& .MuiPaper-root": {
		borderRadius: 8,
		marginTop: theme.spacing(1),
		minWidth: 350,
		color:
			theme.palette.mode === "light"
				? "rgb(55, 65, 81)"
				: theme.palette.grey[300],
		boxShadow:
			"rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
		"& .MuiMenu-list": {
			padding: "4px 0",
		},
		"& .MuiMenuItem-root": {
			"& .MuiSvgIcon-root": {
				fontSize: 25,
				color: theme.palette.text.secondary,
				// marginRight: theme.spacing(1.5),
			},
			"&:active": {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity
				),
			},
		},
	},
}));


export const ProfileHeader = (props) => {
	
	const vw = window.innerWidth / 100

	const { selection, setselection } = useContext(Context);
	const { Fil, SetFil } = useContext(Context)

	const [ton, setton] = useState("")
	const [isHover, setisHover] = useState(false)


	const setSelectedCountry = props.setSelectedCountry
	const selectedCountry = props.selectedCountry
	const [selectedSource, setSelectedSource] = useState({name: "Toutes les sources", code_name: "all"})
	const [selectedListMiddle, setSelectedListMiddle] = useState("De quoi parle-t-on ?")

	const [anchorSources, setAnchorSources] = useState()
	const [anchorListMiddle, setAnchorListMiddle] = useState()
	const [anchorCountries, setAnchorCountries] = useState()

	const openSources = (event) => setAnchorSources(event.currentTarget)
	const openListMiddle = (event) => setAnchorListMiddle(event.currentTarget)
	const openCountries = (event) => setAnchorCountries(event.currentTarget)
	
	const closeSources = () => setAnchorSources(null)
	const closeListMiddle = () => setAnchorListMiddle(null)
	const closeCountries = () => setAnchorCountries(null)


	return (
		<div style={{display: "flex", flexDirection: "column", flexWrap: 'wrap'}}>
			<div className="profile">
				<div className="icon">
					<Stack direction="row" spacing={2} id="stack_thing_in_header">
						{isHover && <div onMouseEnter={() => setisHover(true)} onMouseLeave={() => setisHover(false)} className="file-input">
							<input
								type="file"
								name="file-input"
								id="file-input"
								className="file-input__input"
							/>
							<label className="file-input__label" for="file-input">
								<svg
									aria-hidden="true"
									focusable="false"
									data-prefix="fas"
									data-icon="upload"
									className="svg-inline--fa fa-upload fa-w-16"
									role="img"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 512 512"
								>
									<path
										fill="currentColor"
										d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
									></path>
								</svg>
							</label>
						</div>
						}

						<img 
							onMouseEnter={() => setisHover(true)} 
							onMouseLeave={() => setisHover(false)} 
							className="iconProfile" 
							src={user.profile_picture || process.env.PUBLIC_URL+"/profile_pic_default_3.jpg" }
						 />
						
						<div className="profilename">
							<h2>
								{ `${user?.firstname} ${user?.lastname}`} 
							</h2>
							<button
								className="btnpreprofile"
								onClick={() => window.location.href = "/Subscription"}
							>
								Passer a la version Premium
							</button>
						</div>
					</Stack>
				</div>
				<div className="name"></div>

				<div className="search">
					<div className="gcse-searchbox" data-gname="uno">
						{ /*   <h1>_____ ICI ______</h1>   */ }
					</div>
				</div>

			</div>

			{ window.location.href[3] != "friends-msg" && 
			<div className="droplist">
				<div className="list1">
					<Button
						style={{ backgroundColor: "#009AAD", paddingHorizontal: 120}}
						id="demo-customized-button"
						className="search_menu_selection"
						aria-controls={ Boolean(anchorSources) ? "demo-customized-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={ Boolean(anchorSources) ? "true" : undefined}
						variant="contained"
						disableElevation
						onClick = { openSources }
						endIcon={<KeyboardArrowDownIcon />}
					>

						{ selectedSource.name }

					</Button>

					<StyledMenu
						id="demo-customized-menu"
						MenuListProps={{
							"aria-labelledby": "demo-customized-button",
						}}
						open = { Boolean(anchorSources) }
						onClose = { closeSources }
						anchorEl={ anchorSources }
					>
					<ul className="ulflag">
						{
							sourceTypes.map((s, i) => 							
								<div className="actuins" onClick = {() => setSelectedSource(s)} key={i}> 
									<li> <img className="startArticle" src={etoille}/> <a href="#">{s.name}</a> </li>
								</div>
							)
						}
					</ul>
						{/* <Divider sx={{ my: 0.5 }} /> */}
					</StyledMenu>
				</div>

				<div className="list2">
					<Button
						style={{ backgroundColor: "#009AAD", minWidth: vw * 17}}
						id="demo-customized-button"
						className="search_menu_selection"
						aria-controls={ Boolean(anchorListMiddle) ? "demo-customized-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={ Boolean(anchorListMiddle) ? "true" : undefined}
						variant="contained"
						disableElevation
						onClick = { openListMiddle }
						endIcon={<KeyboardArrowDownIcon />}
					>

						{ selectedListMiddle }

					</Button>
					<StyledMenu1
						id="demo-customized-menu"
						MenuListProps={{
							"aria-labelledby": "demo-customized-button",
						}}
						anchorEl={ anchorListMiddle }
						open={ Boolean(anchorListMiddle) }
						onClose={ closeListMiddle }
					>
						{
							listMiddle.map((item, index) => 							
								<div className="actions" onClick = {() => setSelectedListMiddle(item)} key={index}> 
									<span style={{ color: "black" }} > { item } </span>
								</div>
							)
						}
					</StyledMenu1>
				</div>

				<div className="list3">
					<Button
						style={{ backgroundColor: "#009AAD", paddingHorizontal: 120}}
						className="search_menu_selection"
						id="demo-customized-button"
						aria-controls={ Boolean(anchorCountries) ? "demo-customized-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={ Boolean(anchorCountries) ? "true" : undefined}
						variant="contained"
						disableElevation
						onClick={ openCountries }
						endIcon={<KeyboardArrowDownIcon />}
					>

						{ selectedCountry.name }

					</Button>
					<StyledMenu
						id="demo-customized-menu"
						MenuListProps={{
							"aria-labelledby": "demo-customized-button",
						}}
						anchorEl={ anchorCountries }
						open={ Boolean(anchorCountries) }
						onClose={ closeCountries }
					>

					<ul className="ulflag">
					{
						countries.map((c, i) => 
							<div className="divli" onClick={() => { setSelectedCountry(c); closeCountries()} } key={i}> 
								<li> 
									<div className='divstart'>  <img className="startArticle" src={etoille} /> </div>   
									<img src={process.env.PUBLIC_URL + '/flags/' + c.code + ".svg"} /><a href="#">{c.name}</a>
								</li>
							</div>
						)
					}
					</ul>

					{/* <Divider sx={{ my: 0.5 }} /> */}
					</StyledMenu>
				</div>
			</div>

		}



	</div>

	)
}
