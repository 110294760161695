import "../Component/Home/Home.css"
import Side from "../Component/sidecontainer/side";

import homePage from "../media/accpage.svg";
import amis from "../media/amis.svg";
import conversation_apropo from "../media/conversation_apropo.svg";
import fils from "../media/fils.svg";
import audioCallFont from "../media/audioCallFont.jpeg";
import videocall from "../media/videoFont.png"
import cover from "../media/font.jpeg";

const locationInfo = {
    "/": {
        bgImage: homePage,
        sideBar: "thematics"
    },
    "search": {
        bgImage: fils,
        sideBar: "thematics"
    },
    "friends-msg": {
        bgImage: cover,
        sideBar: "friends_requests"
    },
    "profile": {
        bgImage: cover,
        sideBar: "empty"
    },
    "friends": {
        bgImage: amis,
        sideBar: "friends_requests"
    },
    "favourites": {
        bgImage: amis,
        sideBar: "empty"
    },
    "conv": {
        bgImage: conversation_apropo,
        sideBar: "friends_requests"
    },
    "feed": {
        bgImage: cover,
        sideBar: "empty"
    },
    "followed": {
        bgImage: cover,
        sideBar: "empty"
    },
    "audiocall": {
        bgImage: audioCallFont,
        sideBar: "empty"
    },    
    "videocall": {
        bgImage: videocall,
        sideBar: "empty"
    }

} 


export default function GeneralContainerWrapper(Component) {
    let currentPath = (window.location.href.split("/")[3])
    if (!locationInfo[currentPath]) {
        currentPath = "/"
    }

    return (props) => {
        return (
            <div
                className="homecontainer"
                style = {{ backgroundImage: `url(${ locationInfo[currentPath].bgImage })` }}   
            >
                <div className="sidprof">
                    <div style={{ display: "flex", width: "100%", minHeight: "100vh" }}>
                        <Side sideBarLowerSection = { locationInfo[currentPath].sideBar }/>
                        <div className="allcontainer">
                            <Component/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}



