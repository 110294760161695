import { useContext, useEffect, useState } from "react";
import { Context } from "../../Helper/Context";
import {ProfileHeader } from "./profileHeader"
import "./GoogleCSE.css"
import "./articles.css"
import externalScripts, { removeScript } from "../../Helper/useExternalScripts";
import { countries as allCountries } from "../../global/countriesAndSources"
import { addFavorite } from "../../HTTP/articles"
import { htmlDecode } from "../../Helper/utils"

const userCountry = JSON.parse(localStorage.getItem('user'))?.country

const launchSearch = () => {
    if (window.launchSearchDone == true) return // to avoid recursion stack
    window.launchSearchDone = false
    setTimeout(() => {
        const input = document.querySelector("input#gsc-i-id1")
        if (!input) {
            launchSearch() // recursion until element is found
            return
        }
        const query = sessionStorage.getItem("search_query")
        if (query) {
            for (let i = 0; i < 5; i++){setTimeout(() => input.value = query, 500)}
        }
        const button = document.querySelector("button.gsc-search-button-v2")
        if (button){
            for (let i = 0; i < 5; i++){setTimeout(() => button.click(), 500)}
        }
        window.launchSearchDone = true
    }, 500)
}


export default function GoogleCSE () {
    const [autoSearchLaunched, setAutoSearchLaunched] = useState(false)

    // See if country and query are set as url params.
    const queryCountry = new URLSearchParams(window.location.search).get('country')
    let country = queryCountry ? allCountries.find((c) => c.code === queryCountry) : null
    country = country
            || JSON.parse(sessionStorage.getItem("search_country"))
            || allCountries.find((c) => c.code === JSON.parse(localStorage.getItem('user'))?.country)
            || { name: "France", code: "fr", cse: "004899679196972058950:gvpguoasgrm" }

    const storageQuery = sessionStorage.getItem("search_query")
    if (storageQuery && !autoSearchLaunched) {
        setAutoSearchLaunched(true)
        launchSearch()
    }
    const q = new URLSearchParams(window.location.search).get('q')
            || storageQuery
            || ""

	const [selectedCountry, setSelectedCountry] = useState(country)
	const [query, setQuery] = useState(q)


    const selectNewCountry = (country) => {
        window.location = `./?country=${country.code}&q=${query}`
        sessionStorage.setItem("search_country", JSON.stringify(country))
    }

	//const [clickedFavorites, setClickedFavorites] = useState([])

    /*function setNewQuery (query) {
        var url = new URL(window.location.href)
        url.searchParams.append('q',query)
        window
    }*/

    useEffect(() => {
        externalScripts({
          url: `https://cse.google.com/cse.js?cx=${selectedCountry.cse}`,
          type: "async",
          id: "google_cse",
        })
        //return () => removeScript("google_cse")
    }, [selectedCountry])

    useEffect(() => {
		setTimeout(() => {
			let searchBar = document.getElementById("gsc-i-id1")
			if (searchBar && searchBar.placeholder != "Recherche...") {
                searchBar.style.background = null
				searchBar.placeholder = "Recherche..."
			}
		}, 300)
	})

    useEffect(() => { 
        
        // input change for searchbar
        window.addEventListener("change", (e) => {
            if (e.target.id = "gsc-i-id1") { 
                if (e.target.value == undefined) return
                if (e.target.value.length > 0) { 
                    console.log("input changed")
                    setQuery(e.target.value); 
                    sessionStorage.setItem("search_query", e.target.value) 
                }
            }
        })

        window.previousPage = 0
        window.intervalCount = 0
        const replaceUrl = "http://translate.zemus.fr/translate.php"
        function setIntervalCustom (func, time) {
            window.intervalCount ++
            return setInterval(func, time)
        }
        // Reloads changeTitles until it finds the dom elements
        window.interval = setIntervalCustom(changeTitles, 150)

        // restart interval when click on pagination, search, or select lang button
        window.addEventListener("click", (e) => {
            if (e.target.classList.contains("gsc-cursor-page")) {
                const previous = document.querySelector(".gsc-cursor-current-page")
                if (previous) window.previousPage = previous.innerText
                window.interval = setIntervalCustom(changeTitles, 100)
            }
            if (e.target.classList.contains("gsc-search-button") || e.target.classList.contains("gs-spelling")) { 
                clearInterval(window.interval)
                window.interval = setIntervalCustom(changeTitles, 1700)
            }
            if (e.target.classList.contains("gsc-search-button")) {
                setQuery(document.getElementById("gsc-i-id1").value)
            }
        })

        function deleteStuff() {
            let adBlock = document.querySelector(".gsc-adBlock")
            if (adBlock) adBlock.style.display = "none"

            let refinementBlock = [].filter.call( document.querySelectorAll('.gsc-positioningWrapper') || [], (e) => e.querySelector('.gsc-refinementBlock') != null )[0]
            if (refinementBlock) refinementBlock.style.display = "none"
        }
      
        function addToFav(event) {
            if (event.target.dataset.favorited){
                return
            }
            let contentDiv = event.target.parentElement.parentElement.querySelector("div.gsc-result").querySelector(".gs-result")
            let titleAnchor = contentDiv.querySelector(".gsc-thumbnail-inside").querySelector(".gs-title").querySelector("a.gs-title")
            let realURL = new URL(titleAnchor.href).searchParams.get("article")
            let imageURL = contentDiv.querySelector("div.gsc-table-result").querySelector("div.gsc-table-cell-thumbnail")?.querySelector("div.gs-image-box")?.querySelector("a").querySelector("img").src
            imageURL = imageURL || ""
            let description = contentDiv.querySelector("div.gsc-table-result").querySelector("div.gsc-table-cell-snippet-close")?.querySelector(".gs-snippet")?.innerText || ""
            let formattedDate = new Date().toISOString().replace("T", " ").slice(0, 19)
            const favorite = {
                title: htmlDecode(titleAnchor.textContent),
                link: htmlDecode(realURL), 
                image: htmlDecode(imageURL), //decodeURI(realURL) || decodeURIComponent(realURL)
                country: selectedCountry.name,
                publication_date: formattedDate, // Par défault, ou "2000-01-01 00:00:00"
                description: description
            }
            console.log(formattedDate)
            addFavorite(favorite).then((res) => {
                //setClickedFavorites(...clickedFavorites, favorite.title)
                event.target.src=`${process.env.PUBLIC_URL}/bookmark.svg`
                event.target.dataset.favorited = "true"
            })
        }

        function addBookMark(results, resultsContainer) {
            results.forEach((result, i) => {
                // if the wrapper already exists, return
                if (document.getElementById("wrapper_div_" + i)) return
                const div = document.createElement("div")
                div.id = ("wrapper_div_" + i)
                div.classList.add("wrapper_for_bookmark")
                div.style.cssText = `
                    height: 100px;
                    display: flex; 
                    flexDirection: row;
              `
                div.appendChild(result)
                resultsContainer.appendChild(div)

                 /*const isFavorited = clickedFavorites.map((title) => {
                    if (title == div.querySelector("div.gsc-result").querySelector(".gs-result").querySelector(".gsc-thumbnail-inside").querySelector(".gs-title").querySelector("a.gs-title")){
                        return true
                    }
                })*/
                const previous = document.getElementById("bookmark_"+i)
                if (!previous) div.insertAdjacentHTML("beforeend", 
                    `<div style="position: absolute; right: 60px; padding-top: 28px; " id=${"bookmark_"+i}>
                    <img
                        class="favButton"
                        src="${process.env.PUBLIC_URL}/bookmarklight.svg"
                        alt="bookmark vector image"
                        height="40"
                        width="55" />
                    </div>`
                )
                document.querySelectorAll(".favButton").forEach((e) => e.onclick = addToFav)
            })
        }

        function changeTitles() {
            if (window.intervalCount < 0) return
            let titles = document.querySelectorAll("a.gs-title")
            let adTitles = document.getElementsByClassName("p_")
            let results = document.querySelectorAll("div.gsc-result")
            const resultsContainer = document.querySelector(".gsc-expansionArea")
            titles = [...titles, ...adTitles]

            deleteStuff()

            // Si les résultats sont affichés
            if (titles.length > 0  && results.length > 0) {
                const current = document.querySelector(".gsc-cursor-current-page")?.innerText
                if (current == undefined || current === window.previousPage ) {
                    console.log("CURRENT PAGE === ", current )
                    return
                    /* Wait for the  elements of the next page to apppear. Otherwise it runs on the
                       element of the previous page */
                }

                clearInterval(window.interval)

                // trouble here clearing all the intervals. changeTitle keeps running
                for (let i = 0; i <= window.intervalCount; i++){
                    window.intervalCount --
                    window.clearInterval(window.interval)
                } 
                console.log("window.intervalCount ", window.intervalCount)
                //console.log("window.interval ", window.interval)

 
                addBookMark(results, resultsContainer)

                titles.forEach(e => {
                    const selected_country_code = selectedCountry.code || "fr"
                    const selected_lang_code = userCountry || "fr"
                    //console.log("selected_country_code", selected_country_code)
                    //console.log("selected_lang_code", selected_lang_code)


                    // Si le href a déjà été modifié, return
                    if (e.href.includes(replaceUrl)) {
                        return
                    }

                    e.dataset.hostname = e.href.split("/")[2]
                    e.dataset.cturl = "#"
                    e.target = "_blank"

                    //console.log("href AVANT", e.href)
                    e.setAttribute("href", replaceUrl + "?site=" + e.href + "&article=" + e.href + "&lgsrc=" + selected_country_code + "&lgdst=" + selected_lang_code);
                    //console.log("e.href APRES", e.href)
                })
            }
	    }
    }, [])


    return (
        <>
            <ProfileHeader 
                selectedCountry={selectedCountry} 
                setSelectedCountry = {(country) => selectNewCountry(country)}
            />
            <h1></h1>
            <div className="gcse-searchresults" data-gname="uno"></div>
        </>
    )
}

